<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          lg="4"
          xl="4"
        >
          <b-form-group>
            <label for="">Đơn vị báo cáo<span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="{required:'Đơn vị báo cáo là bắt buộc'}"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-col>
        <b-col
          lg="4"
          xl="4"
        >
          <label for="">Kỳ báo cáo<span class="text-danger">(*)</span></label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="periodReport"
              rules="required"
              :custom-messages="{required:'Kỳ báo cáo là bắt buộc'}"
            >
              <v-select
                v-model="dataInput.periodReport"
                :reduce="title => title.en"
                label="vi"
                :options="periodReport || []"
                :class="{'is-invalid':errors.length > 0 }"
                :placeholder="'Lựa chọn Kỳ báo cáo'"
                :state="errors.length > 0 ? false:null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          lg="4"
          xl="4"
        >
          <b-form-group>
            <label for="abc">Năm báo cáo<span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="year"
              rules="required"
              :custom-messages="{required:'Năm báo cáo là bắt buộc'}"
            >
              <b-form-input
                v-model="dataInput.year"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          lg="4"
          xl="4"
        >
          <b-form-group>
            <label for="">Ngày báo cáo<span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="dateReport"
              rules="required"
              :custom-messages="{required:'Ngày báo cáo là bắt buộc'}"
            >
              <date-time-picker
                v-model="dataInput.dateReport"
                :disabledInput="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    DateTimePicker,
    vSelect,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      periodReport: [
        {
          en: 'SixMonth',
          vi: '6 tháng',
        },
        {
          en: 'Year',
          vi: 'Năm',
        },
      ],
    }
  },
}
</script>

<style>

</style>
