<template>
  <b-modal
    :id="id"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    size="xl"
    no-close-on-backdrop
    :title="title"
    @ok="accept($event)"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <b-form>
        <b-row>
          <b-col
            xl="4"
            md="4"
          >
            <b-form-group>
              <label for="">Tên chỉ tiêu thống kê<span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="{required: 'Tên chỉ tiêu thống kê là bắt buộc'}"
              >
                <b-form-input
                  v-model="dataInput.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >
            <b-form-group>
              <label for="">Mã số</label>
              <b-form-input
                v-model="dataInput.code"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >
            <b-form-group>
              <label for="">Tổng số vụ TNLĐ</label>
              <b-form-input
                v-model="dataInput.totalCase"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >
            <b-form-group>
              <label for="">Số vụ có người chết</label>
              <b-form-input
                v-model="dataInput.totalDeathCase"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >
            <b-form-group>
              <label for="">Số vụ có từ 2 người bị tai nạn trở lên</label>
              <b-form-input
                v-model="dataInput.total2InjuryCase"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Tổng số người bị tai nạn</label>
              <b-form-input
                v-model="dataInput.totalWorker"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số lao động nữ bị tai nạn</label>
              <b-form-input
                v-model="dataInput.totalFemaleWorker"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số người chết</label>
              <b-form-input
                v-model="dataInput.deathWorker"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số người bị thương nặng</label>
              <b-form-input
                v-model="dataInput.seriouslyInjuryWorker"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Tổng số ngày nghỉ vì tai nạn lao động</label>
              <b-form-input
                v-model="dataInput.absent"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Chi phí y tế</label>
              <b-form-input
                v-model="dataInput.healthCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Chi phí trả lương trong thời gian điều trị</label>
              <b-form-input
                v-model="dataInput.salaryCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Chi phí bồi thường/trợ cấp</label>
              <b-form-input
                v-model="dataInput.indemnifyCost"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Thiệt hại tài sản</label>
              <b-form-input
                v-model="dataInput.assetCost"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BForm,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      dataInput: {
        accidentSpecializeReportId: this.$route.params.id,
        code: '',
        name: '',
        accidentSpecializeTitleType: '',
        totalCase: 0,
        totalDeathCase: 0,
        total2InjuryCase: 0,
        totalWorker: 0,
        totalFemaleWorker: 0,
        deathWorker: 0,
        seriouslyInjuryWorker: 0,
        absent: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        assetCost: 0,
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = this.dataDetail
      }
    },
  },
  methods: {
    resetData() {
      this.dataInput = {
        accidentSpecializeReportId: this.$route.params.id,
        code: '',
        name: '',
        accidentSpecializeTitleType: '',
        totalCase: 0,
        totalDeathCase: 0,
        total2InjuryCase: 0,
        totalWorker: 0,
        totalFemaleWorker: 0,
        deathWorker: 0,
        seriouslyInjuryWorker: 0,
        absent: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        assetCost: 0,
      }
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
  },
}
</script>

<style>

</style>
