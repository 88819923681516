<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm báo cáo'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideDowload="false"
      :hideImportFile="false"
      :hideExportFile="false"
      @importFile="importFileExcel"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span>{{ props.row.name }}</span>
        </span>
        <span
          v-else-if="props.column.field === 'periodReport'"
          class="text-nowrap"
        >
          <span>{{ props.row.periodReportString }}</span>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            @click="showPDF(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xem báo cáo'"
              icon="EyeIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- <edit-element
      :id="idModal"
      :dataDetai="dataDetai"
      :title="'Thêm báo cáo'"
      :idDetail="idDetail"
      @accept="handleAccept"
    /> -->
    <ModalSeePDF
      :id="modalSeePdf"
      :pdfsrc="pdfSrc"
      @downloadFile="downloadFilePdf"
    />
  </div>
</template>

<script>
import { BBadge, VBTooltip } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import EditElement from './components/EditElement.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalSeePDF from '@/views/management-workers/worker-license/pages/components/ModalSeePdf.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    ConfirmModal,
    FeatherIcon,
    BBadge,
    EditElement,
    ModalSeePDF,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      modalSeePdf: 'modal-see-pdf',
      arrayExcel: [
        'code',
        'name',
      ],
      pdfSrc: null,
      idModal: 'modal-create-accident-specialize',
      dataList: [],
      showBtnMultiDelete: false,
      urlQuery: {
        pageNumber: 1,
        pageSize: 10,
        key: '',
      },
      columns: [
        {
          label: 'ĐƠN VỊ BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'KỲ BÁO CÁO',
          field: 'periodReport',
          sortable: false,
        },
        {
          label: 'NĂM BÁO CÁO',
          field: 'year',
          sortable: false,
        },

        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      totalRecord: 0,
      idDetail: null,
      dataDetai: {},
    }
  },

  created() {
    this.getList()
  },

  methods: {
    // ...mapActions('injuryElement', [
    //   'downloadExportFile',
    //   'downloadExampleFile',
    //   'getApiExcel',
    // ]),

    showModalCreate() {
      this.$router.push({ name: 'accedent-specialize-add' })
    },
    async showModalEdit(id) {
      this.$router.push({ name: 'accedent-specialize-edit', params: { id } })
    },

    async getList() {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST, {
        params: this.urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getList()
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.getList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.getList()
      }
    },

    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-injury-element' })
    },

    showPDF(id) {
      const paload = {
        accidentSpecializeReportId: id,
        reportFormat: 'PDF',
      }
      axiosApiInstance({
        url: ConstantsApi.PDF_REPORT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfSrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      axiosApiInstance({
        url: ConstantsApi.PDF_REPORT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { workerLicenseId: this.workerLicenseAcceptId, type: this.typeStatus },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'BaoCaoTongHopLaoDongChuyenNganh.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

  },
}
</script>

<style>

</style>
