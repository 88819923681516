<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container-table pr-1">

      <validation-observer ref="tabAccount">
        <infor-general
          v-if="isRendered('info')"
          key="info"
          idImport="info"
          :dataInput="dataInput"
          class="tab-pane ml-1"
          :class="{ active: activeTab === 'info' }"
        />

        <report-type
          v-if="isRendered('baseType')"
          key="baseType"
          idImport="baseType"
          class="tab-pane"
          :class="{ active: activeTab === 'baseType' }"
        />

        <Table
          v-if="isRendered('Total')"
          key="Total"
          tab="Total"
          :dataTable="dataListTitle"
          class="tab-pane"
          :class="{ active: activeTab === 'Total' }"
          @reloadTable="getListTitle()"
        />
        <Table
          v-if="isRendered('BussinessType')"
          tab="BussinessType"
          class="tab-pane"
          :dataTable="dataListTitle"
          :class="{ active: activeTab === 'BussinessType' }"
          @reloadTable="getListTitle()"
        />
        <Table
          v-if="isRendered('ReasonInjury')"
          tab="ReasonInjury"
          :dataTable="dataListTitle"
          class="tab-pane"
          :class="{ active: activeTab === 'ReasonInjury' }"
          @reloadTable="getListTitle()"
        />
        <Table
          v-if="isRendered('ElementInjury')"
          tab="ElementInjury"
          class="tab-pane"
          :class="{ active: activeTab === 'ElementInjury' }"
          @reloadTable="getListTitle()"
        />
        <Table
          v-if="isRendered('Job')"
          tab="Job"
          :dataTable="dataListTitle"
          class="tab-pane"
          :class="{ active: activeTab === 'Job' }"
          @reloadTable="getListTitle()"
        />

      </validation-observer>
      <div class="mt-1">

        <b-button
          class="ml-1"
          variant="primary"
          @click="handleAccept($event)"
        >
          Lưu lại
        </b-button>
        <b-button
          class="ml-1"
          variant="outline-primary"
          to="/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach"
        >
          Quay lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BModal,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InforGeneral from './InforGeneral.vue'
import ReportType from './ReportType.vue'
import Table from './Table.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    MyTabs,
    InforGeneral,
    ReportType,
    Table,
    BButton,
  },

  props: {
    id: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      key: true,
      required,
      dataInput: {
        name: '',
        periodReport: '',
        year: '',
        dateReport: null,
      },
      activeTab: null,
      dataDetail: {},
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin chung',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'baseType',
          title: 'Phân loại theo loại hình cơ sở',
          icon: 'ListIcon',
          isRendered: false,
          isDisabled: true,
        },
        {
          key: 'Total',
          title: 'Tổng số',
          icon: 'CodeIcon',
          isRendered: false,
          isDisabled: true,
        },
        {
          key: 'BussinessType',
          title: 'Phân theo ngành',
          icon: 'BriefcaseIcon',
          isRendered: false,
          isDisabled: true,
        },
        {
          key: 'ReasonInjury',
          title: 'Phân theo nguyên nhân',
          icon: 'CheckIcon',
          isRendered: false,
          isDisabled: true,
        },
        {
          key: 'ElementInjury',
          title: 'Phân theo yếu tố chấn thương',
          icon: 'UsersIcon',
          isRendered: false,
          isDisabled: true,
        },
        {
          key: 'Job',
          title: 'Phân theo nghề nghiệp',
          icon: 'BriefcaseIcon',
          isRendered: false,
          isDisabled: true,
        },
      ],

      dataListType: [],
      totalRecordType: 0,
      dataListTitle: [],
      totalRecordTitle: 0,
    }
  },

  watch: {
    dataDetail(val) {
      this.dataInput.name = this.dataDetail.name
      this.dataInput.periodReport = this.dataDetail.periodReport
      this.dataInput.year = this.dataDetail.year
      this.dataInput.dateReport = this.dataDetail.dateReport
    },
  },

  created() {
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id)
      this.listTabs.forEach(element => {
        if (element.key) {
          element.isDisabled = false
        }
      })
    }
  },

  methods: {
    handleAccept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(async success => {
        if (success) {
          if (!this.$route.params.id) {
            this.add(this.dataInput)
          } else {
            this.edit(this.dataInput)
          }
        }
      })
    },

    add(val) {
      const payload = {
        ...val,
        year: parseInt(val.year, 10),
      }
      axiosApiInstance.post(ConstantsApi.CREATE, payload).then(response => {
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'accedent-specialize-edit', params: { id: response?.data?.data?.id } })
        this.listTabs.forEach(element => {
          if (element.key) {
            element.isDisabled = false
          }
        })
      }).catch(e => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    edit(val) {
      const payload = {
        ...val,
        year: parseInt(val.year, 10),
        id: this.$route.params.id,
      }
      axiosApiInstance.put(ConstantsApi.EDIT, payload).then(response => {
        this.$root.$bvToast.toast('chỉnh sửa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push('/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach')
      }).catch(e => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async getDetail(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL}${id}`)
      this.dataDetail = data
    },
    // lấy dnah sách phân loại báo cáo

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) {
        tab.isRendered = true
      }
    },

    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    resetData() {
      this.activeTab = 'info'
    },

  },
}
</script>

<style lang="scss" scoped>
.my-tab-custom {
  box-shadow: unset;
  padding: 15px 0 0 0 !important;
}

</style>
