var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":""}},[_vm._v("Đơn vị báo cáo"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":{required:'Đơn vị báo cáo là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.name),callback:function ($$v) {_vm.$set(_vm.dataInput, "name", $$v)},expression:"dataInput.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","xl":"4"}},[_c('label',{attrs:{"for":""}},[_vm._v("Kỳ báo cáo"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"periodReport","rules":"required","custom-messages":{required:'Kỳ báo cáo là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (title) { return title.en; },"label":"vi","options":_vm.periodReport || [],"placeholder":'Lựa chọn Kỳ báo cáo',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.periodReport),callback:function ($$v) {_vm.$set(_vm.dataInput, "periodReport", $$v)},expression:"dataInput.periodReport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"abc"}},[_vm._v("Năm báo cáo"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"year","rules":"required","custom-messages":{required:'Năm báo cáo là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.year),callback:function ($$v) {_vm.$set(_vm.dataInput, "year", $$v)},expression:"dataInput.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":""}},[_vm._v("Ngày báo cáo"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"dateReport","rules":"required","custom-messages":{required:'Ngày báo cáo là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.dateReport),callback:function ($$v) {_vm.$set(_vm.dataInput, "dateReport", $$v)},expression:"dataInput.dateReport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }