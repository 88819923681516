<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm mới'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideExportFile="false"
      @clickDelete="deleteItems"
      @clickDowloadSample="downloadExampleFileType"
      @importFile="importFileExcel"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <!-- <b-form-group
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox-group
        v-model="deleteIds"
        :aria-describedby="ariaDescribedby"
        name="flavour-2"
        @change="selectRows"
      > -->
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th>
            <b-form-checkbox
              :checked="isSelectedAll"
              :indeterminate="indeterminateCheckin"
              @change="selectAllRows($event)"
            /></b-th>
          <b-th
            colspan="1"
          >
            LOẠI HÌNH CƠ SỞ
          </b-th>
          <b-th
            colspan="1"
          >
            MÃ SỐ
          </b-th>
          <b-th
            class="border-bottom-column"
            colspan="2"
          >
            CƠ SỞ
          </b-th>
          <b-th
            class="border-bottom-column"
            colspan="3"
          >
            LỰC LƯỢNG LAO ĐỘNG
          </b-th>
          <b-th
            class="border-bottom-column"
            colspan="3"
          >
            TỔNG SỐ TNLĐ
          </b-th>
          <b-th
            class="border-bottom-column"
            colspan="2"
          >
            TẦN SUẤT TNLĐ
          </b-th>
          <b-th
            colspan="1"
            style="width: 120px"
          >
            CHỨC NĂNG
          </b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>
            TỔNG SỐ
          </b-th>
          <b-th>
            SỐ CƠ SỞ THAM GIA BÁO CÁO
          </b-th>
          <b-th>
            TỔNG SỐ LAO ĐỘNG
          </b-th>
          <b-th>
            SỐ LĐ CỦA CƠ SỞ THAM GIA BÁO CÁO
          </b-th>
          <b-th>
            sỐ LAO ĐỘNG NỮ
          </b-th>
          <b-th
            colspan="3"
            class="border-bottom-column"
          >
            TỔNG SỐ TNLĐ
          </b-th>
          <b-th>
            <span>K</span><span class="small-characters">TNLĐ</span>
          </b-th>
          <b-th>
            <span>K</span><span class="small-characters">CHẾT</span>
          </b-th>
          <b-th></b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>
            TỔNG SỐ
          </b-th>
          <b-th>
            SỐ NGƯỜI BỊ CHẾT
          </b-th>
          <b-th>
            SỐ NGƯỜI BỊ THƯƠNG
            NẶNG
          </b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>

      <b-tbody
        v-if="dataTable.length > 0"
      >
        <b-tr
          v-for="(item, index) in dataTable"
          :key="index"
        >

          <b-td class="pb-25">
            <b-form-checkbox
              :checked="item.isSelected"
              @input="selectRowTable($event, index)"
            >
            </b-form-checkbox>
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.name }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.code }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalBussiness }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalBussinessReport }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalWorkerReport }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalFemaleWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalAccidentWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.deathWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.seriouslyInjuryWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.kDeath }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.kAccident }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <feather-icon
              icon="Edit3Icon"
              size="18"
              class="text-body ml-2"
              @click="showModalEdit(item.id)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>

        </b-tr>

      </b-tbody>

    </b-table-simple>
    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <div v-if="dataTable.length === 0">
      <div class="empty-table d-block">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-img
            class="img-empty-table"
            fluid
            :src="$emptyImg"
            alt="empty table"
          />
        </div>
      </div>
    </div>
    <modal-create-report
      :id="modalReport"
      :title="'Thêm mới'"
      :dataDetail="dataDetail"
      @accept="handleAccept"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BImg,
  BThead,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateReport from './ModalCreateReport.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    ButtonAllHeader,
    BFormCheckbox,
    ConfirmModal,
    MyPagination,
    BImg,
    ModalCreateReport,
  },

  data() {
    return {
      modalReport: 'modalReport',
      showBtnMultiDelete: false,
      arrayExcel: [
        'name',
        'code',
        'totalBussiness',
        'totalBussinessReport',
        'totalWorker',
        'totalWorkerReport',
        'totalFemaleWorker',
        'totalAccidentWorker',
        'deathWorker',
        'seriouslyInjuryWorker',
      ],
      urlQuery: {
        accidentSpecializeReportId: this.$route.params.id,
        key: '',
        pageNumber: 1,
        pageSize: 10,
      },
      totalRecord: 0,
      idDetail: null,
      dataDetail: {},
      deleteIds: [],
      isIndeterminate: null,
      modalContent: '',
      confirmModalId: 'deleteType',
      dataTable: [],
    }
  },
  computed: {
    idCourse() {
      return this.$route.params.id
    },
    isSelectedAll() {
      return !this.dataTable.find(item => !item.isSelected) && this.dataTable.length > 0
    },
    indeterminateCheckin() {
      const checkedRows = this.dataTable.filter(item => item.isSelected)
      return checkedRows.length > 0 && checkedRows.length !== this.dataTable.length
    },
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions('accidentSpecializeReport', [
      'downloadExampleFileType',
      'getApiExcelType',
    ]),
    // Chọn người dùng để thực hiện action
    selectRowTable(val, index) {
      const selectedRow = this.dataTable[index]
      selectedRow.isSelected = val
      this.dataTable.splice(index, 1, selectedRow)
      const rowSelected = this.dataTable.filter(item => item.isSelected === true)
      if (selectedRow) this.showBtnMultiDelete = rowSelected.length > 0
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getList()
    },

    selectAllRows(val) {
      const cloneRows = this.dataTable
      cloneRows.forEach(item => {
        item.isSelected = val
      })
      this.dataTable = cloneRows
    },

    // xóa một người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      const tableRows = this.dataTable.filter(item => item.isSelected === true)
      this.deleteIds = tableRows.map(item => item.id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      const payload = {
        ids: this.deleteIds,
        accidentSpecializeId: this.$route.params.id,
      }
      await axiosApiInstance.post(ConstantsApi.DELETE_TYPE, payload).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    showModalCreate() {
      this.$bvModal.show(this.modalReport)
    },
    // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      dataInput.forEach(element => {
        Object.entries(element).forEach(([key, value]) => {
          if (key !== 'code' && key !== 'name') {
            element[key] = value ? parseInt(value, 10) : 0
          }
        })
      })
      const model = {
        accidentSpecializeReportId: this.$route.params.id,
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelType(model)
      await this.$router.push({ name: 'accedent-specialize-type-import' })
    },

    async showModalEdit(id) {
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_TYPE}${id}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalReport)
    },

    handleAccept(val) {
      if (!this.idDetail) {
        this.addReportType(val)
      } else {
        this.editReportType(val)
      }
    },
    addReportType(val) {
      const payload = {
        ...val,
        totalBussiness: parseInt(val.totalBussiness, 10),
        totalBussinessReport: parseInt(val.totalBussinessReport, 10),
        totalWorker: parseInt(val.totalWorker, 10),
        totalWorkerReport: parseInt(val.totalWorkerReport, 10),
        totalFemaleWorker: parseInt(val.totalFemaleWorker, 10),
        totalAccidentWorker: parseInt(val.totalAccidentWorker, 10),
        deathWorker: parseInt(val.deathWorker, 10),
        seriouslyInjuryWorker: parseInt(val.seriouslyInjuryWorker, 10),
        accidentSpecializeId: this.$route.params.id,
      }
      axiosApiInstance.post(ConstantsApi.ADD_TYPE, payload).then(response => {
        this.$bvToast.toast('Thêm loại hình cơ sở thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
        this.$bvModal.hide(this.modalReport)
      }).catch(e => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    editReportType(val) {
      const payload = {
        ...val,
        totalBussiness: parseInt(val.totalBussiness, 10),
        totalBussinessReport: parseInt(val.totalBussinessReport, 10),
        totalWorker: parseInt(val.totalWorker, 10),
        totalWorkerReport: parseInt(val.totalWorkerReport, 10),
        totalFemaleWorker: parseInt(val.totalFemaleWorker, 10),
        totalAccidentWorker: parseInt(val.totalAccidentWorker, 10),
        deathWorker: parseInt(val.deathWorker, 10),
        seriouslyInjuryWorker: parseInt(val.seriouslyInjuryWorker, 10),
        accidentSpecializeId: this.$route.params.id,
        id: this.idDetail,
      }
      axiosApiInstance.post(ConstantsApi.EDIT_TYPE, payload).then(response => {
        this.$bvToast.toast('chỉnh sửa loại hình cơ sở thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
        this.$bvModal.hide(this.modalReport)
      }).catch(e => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    async getList() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_TYPE, {
        params: this.urlQuery,
      })
      data?.data?.pageLists.forEach(element => {
        element.isSelected = false
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
    },

    search(val) {
      this.urlQuery.key = val
      this.urlQuery.pageNumber = 1
      this.urlQuery.pageSize = 10
    },
  },
}
</script>

<style lang="scss" scoped>

  .table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
}
.table thead th {
  border: unset;
}
.border-bottom-column {
  border-bottom: 1px solid #CBCDD2 !important;
}
.small-characters {
  font-size: 9px;
}
</style>
