<template>
  <b-modal
    :id="id"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    size="xl"
    no-close-on-backdrop
    :title="title"
    @ok="accept($event)"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <b-form>
        <b-row>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Loại hình cơ sở<span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="{required: 'Loại hình cơ sở là bắt buộc'}"
              >
                <b-form-input
                  v-model="dataInput.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Mã số<span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name=""
                rules="required"
                :custom-messages="{required: 'Mã số là bắt buộc'}"
              >
                <b-form-input
                  v-model="dataInput.code"

                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Tổng số</label>
              <b-form-input v-model="dataInput.totalBussiness" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số cơ sở tham gia báo cáo</label>
              <b-form-input v-model="dataInput.totalBussinessReport" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Tổng số lao động</label>
              <b-form-input v-model="dataInput.totalWorker" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số LĐ của cơ sở tham gia báo cáo</label>
              <b-form-input v-model="dataInput.totalWorkerReport" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số lao động nữ</label>
              <b-form-input v-model="dataInput.totalFemaleWorker" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Tổng số người bị nạn</label>
              <b-form-input v-model="dataInput.seriouslyInjuryWorker" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số người bị chết</label>
              <b-form-input v-model="dataInput.deathWorker" />
            </b-form-group>
          </b-col>
          <b-col
            xl="4"
            md="4"
          >

            <b-form-group>
              <label for="">Số người bị thương nặng</label>
              <b-form-input v-model="dataInput.totalAccidentWorker" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BForm,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataInput: {
        accidentSpecializeReportId: this.$route.params.id,
        name: '',
        code: '',
        totalBussiness: 0,
        totalBussinessReport: 0,
        totalWorker: 0,
        totalWorkerReport: 0,
        totalFemaleWorker: 0,
        totalAccidentWorker: 0,
        deathWorker: 0,
        seriouslyInjuryWorker: 0,
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          ...val,
        }
      }
    },
  },
  methods: {
    resetData() {
      this.dataInput = {
        accidentSpecializeReportId: this.$route.params.id,
        name: '',
        code: '',
        totalBussiness: '',
        totalBussinessReport: '',
        totalWorker: '',
        totalWorkerReport: '',
        totalFemaleWorker: '',
        totalAccidentWorker: '',
        deathWorker: '',
        seriouslyInjuryWorker: '',
      }
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
  },
}
</script>

<style>

</style>
